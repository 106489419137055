<template>
  <div>
    <div class="content">
      <div class="all SearchBar" >
        <el-form :inline="true" class="searchBoxForm">
          <el-form-item label="采集名称" class="searchItem">
            <el-input
              size="mini"
              v-model="pageParams.itemName"
              placeholder="请输入采集名称"
              clearable
              class="search"
            ></el-input>
          </el-form-item>
          <el-form-item label="录入类型" class="searchItem">
            <el-select v-model="pageParams.inputType" placeholder="请选择" size="mini" clearable class="search" filterable @change="searchList">
              <el-option
                v-for="item in admission"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="必填项" class="searchItem">
            <el-select v-model="pageParams.requiredFields" placeholder="请选择" size="mini" clearable class="search" filterable @change="searchList">
              <el-option
                v-for="item in requiredList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="使用情况" class="searchItem">
            <el-select v-model="pageParams.used" placeholder="请选择" size="mini" clearable class="search" filterable @change="searchList">
              <el-option
                v-for="item in useCondition"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="启用状态" class="searchItem">
            <el-select v-model="pageParams.generalStatus" placeholder="请选择" size="mini" clearable class="search" filterable @change="searchList">
              <el-option
                v-for="item in enableStart"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" class="searchItem">
             <el-button type="primary" @click="searchList(1)" size="mini" >查询</el-button>
             <el-button type="warning" @click="closeList(1)" size="mini" >重置</el-button>
          </el-form-item>
        </el-form>
    </div>
    <el-divider style="color: #f2f2f2"></el-divider>
      <div class="tableTool">
        <span style="float:right;margin:0.5%;margin-right: 16px;"> 
          <el-button type="text" 
          style="border: 1px solid #409EFF;
    color: #409EFF;
    width: 80px;"
          size="mini" @click="goPath('/home/addCollec')" v-if="$anthButtons.getButtonAuth('fmcjxtj')">添加</el-button>
        </span>
      </div>
      <div >
        <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;padding:0"
            border
            :stripe="true"
            height="calc(100vh - 467px)"
            :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9',textAlign:'left',}"
          >
          <el-table-column
            type="index"
            label="序号"
            width="60"
          >
          </el-table-column>
          <el-table-column
            prop="itemName"
            label="采集名称"
            width="500"
          >
          </el-table-column>
          <el-table-column
            prop="inputType"
            label="录入类型"
            show-overflow-tooltip
          >
            <template slot-scope="{row}">
                <div v-if="row.inputType == 1" >文本框 </div>
                <div v-if="row.inputType == 2" >单选 </div>
                <div v-if="row.inputType == 3" >多选 </div>
                <div v-if="row.inputType == 4" >上传照片 </div>
                <div v-if="row.inputType == 5" >数字框 </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="requiredFields"
            label="必填项"
            show-overflow-tooltip
          >
          <template slot-scope="{row}">
                <div v-if="row.requiredFields == 1" >必填 </div>
                <div v-if="row.requiredFields == 2" >非必填 </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="sortNum"
            label="排序"
            show-overflow-tooltip
          >
          <!-- parseInt(Math.abs(row.sortNum)) -->
            <template slot-scope="{ row }">
              <el-input v-model.number="row.sortNum" 
              type="number"
              placeholder="请输入排序"
              @input="row.sortNum = row.sortNum>9999  ? 9999 : (( row.sortNum< 0) ? 0 : parseInt(row.sortNum))"
              @blur="sortNumber(row)"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="used"
            label="使用情况"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="generalStatus" label="启用状态">
            <template slot-scope="{row}">
                <el-tag v-if="row.generalStatus == 0" type="danger">禁用 </el-tag>
                <el-tag v-if="row.generalStatus == 1" type="success">启用</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="left" width="300">
            <template slot-scope="scope">
              <div class="operateTool">
                <el-button type="primary" @click="goPath('/home/collecDesc',scope.row.id)" size="mini" v-if="$anthButtons.getButtonAuth('fmcjxck')">查看</el-button>
                <el-button type="primary" 
                plain
                v-if="(scope.row.used == '未使用') && (scope.row.generalStatus == 0)&& $anthButtons.getButtonAuth('fmcjxbj')"
                @click="goPath('/home/addCollec',scope.row.id)"
                 size="mini">编辑</el-button>
                <el-button type="success" 
                @click="onShowDesign(scope.row)" 
                size="mini" 
                v-if="scope.row.generalStatus == 0 && $anthButtons.getButtonAuth('fmqyty')"
                style="background:#3DAF40">启用</el-button>
                <el-button type="danger" 
                @click="onShowDesign(scope.row)" 
                size="mini" 
                v-if="scope.row.generalStatus == 1 && $anthButtons.getButtonAuth('fmqyty')"
                style="background:red">停用</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  updateSortNum
} from "../../RequestPort/Login/LoginRequest";
import pageMixins from "@/utils/pageMixins";
import { itemPageList,itemGeneralStatusToggle } from "../../RequestPort/dict/cycle";
export default {
  name:'Collec',
  mixins: [pageMixins],
  data() {
    return {
      pageParams: {
        current: 1,
        size: 100,
      }, //  分页查询参数
      total:0,
      tableData: [{}], //  表格数据
      title:'',
      value:'',
      options:[],
      // 录入类型
      admission:[
        {label:'文本框',value:1},
        {label:'单选',value:2},
        {label:'多选',value:3},
        {label:'上传照片',value:4},
        {label:'数字框',value:5},
      ],
      // 必填项
      requiredList:[
        {label:'必填',value:1},
        {label:'非必填',value:2},
      ],
      // 使用情况
      useCondition:[
        {label:'未使用',value:1},
        {label:'被使用',value:2},
      ],
      // 启用状态
      enableStart:[
        {label:'禁用',value:0},
        {label:'启用',value:1},
      ]
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    //查列表
    getList(){
      itemPageList(this.pageParams).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    /**
     * 排序
     */
    sortNumber(row){
      console.log(row)
      if(!row.sortNum){
        row.sortNum = 0
      }
      let data ={
        id:row.id,
        sortNum:row.sortNum,
        table:'gas_zhonglian.aaum_item'
      }
      updateSortNum(data).then(e => {
        if (e.code === 200) {
            this.$message.success('修改排序成功');
            this.getList()
          }
      })
    },
    /**
     * 搜索
     */
    searchList(){
      this.pageParams.current = 1
      this.getList()
    },
    /**
     * 重置
     */
    closeList(){
      this.pageParams=  {
        current: 1,
        size: 100,
      },
      this.getList()
    },
    //跳转
    goPath(path, id, disable) {
      this.$router.push({ path: path, query: { id: id, disable: disable } })
    },
    /**
     * 启用/停用
     */
    onShowDesign(row) {
      itemGeneralStatusToggle({id:row.id}).then(res => {
        this.$message.success(res.data);
        this.getList()
      })
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.pageParams.current = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.getList()
    }
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.searchItem {
  margin-left: 1%;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  background-color: #ffffff;
}
.all {
  background-color:#fff;height:auto
}
.content {
  background: #fff;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 294px);
}
.search{
  width: 190px;
}
.el-form-item{
  margin-bottom: 0;
  margin-left: 0;
}
.el-divider--horizontal{
  width: 100%;
  margin: 20px auto 0px;
}
</style>